body{
    background : rgb(229 228 228)
}

.MuiInputBase-input{
    height : 3.1876em !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
