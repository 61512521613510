@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  color: white;
}

.nav-bar {
  height: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 2rem;
  color: #222;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-logo {
  height: 60px;
}

.nav-bar a {
  text-decoration: none;
  color: darkblue;
}

.nav-bar a:hover {
  color: orange;
}

.nav-bar .button {
  background-color: #33ff36;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: white;
}

.main-cont {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100%;
}

.card {
  position: absolute;

  width: 400px;
  height: 250px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.55) 100%
  );
  border-radius: 1rem;
  border: 2px solid white;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  transition-duration: 1s;
}

.frontcard {
  backface-visibility: hidden;
}

.chip {
  width: 50px;
  height: 40px;
  background-color: gold;

  position: absolute;
  top: 1.5rem;
  left: 1rem;
  border-radius: 5px;
}

.monzo-logo {
  height: 50px;
  position: absolute;
  top: 1.5rem;
  right: 2rem;
}

.card-number {
  position: absolute;
  top: 50%;
  left: 1rem;
  font-size: 1.7rem;
  text-decoration: none;
  color: white;
}
.name {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.logo {
  height: 30px;
  position: absolute;
  bottom: 0.5rem;
  right: 0.8rem;
}
.main_heading {
  font-size: 20px;
  color: #00796a;
  font-weight: 400;
  border: 1px solid rgb(73, 66, 66);
  border-radius: 4px;
  background: lightcyan;
  padding: 7px 10px;
}
@media only screen and (max-width: 600px) {
  .nav-bar {
    flex-direction: column;
    height: 130px;
    justify-content: center;
  }
  .main_heading {
    font-size: 30px;
    padding-top: 15px;
  }
}
