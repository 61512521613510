/* PublicPickDropQr.css */

.mobile-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .mobile-view img {
    width: 150px;
    height: 50px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .mobile-view div {
    color: black;
    font-size: 30px;
    font-weight: bold;
  }
  
  .web-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .web-view img {
    width: 150px;
    height: 60px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .web-view div {
    color: black;
    font-size: 40px;
    font-weight: bolder;
    margin-top: 20px;
  }
  
  /* Media query to switch between mobile and web view */
  @media (min-width: 768px) {
    .mobile-view {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .web-view {
      display: none;
    }
  }
  