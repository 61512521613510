.css-wjh20t-MuiPagination-ul{
    justify-content:'space-around'
}

/* Custom styles for the filter modal */
.MUIDataTableFilter-root-290 {
    /* background-color: red !important; Replace "your-color" with your desired background color */
background: url('../../utility/filerBg.png');  
left: 500px;
}
.MUIDataTableToolbar-filterPaper-24{
    left: 500px;
    overflow: hidden !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
	display: flex !important;
}

 /* Rebook code */
 .rebook_button {
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	background: linear-gradient(15deg, #880088, #aa2068, #cc3f47, #de6f3d, #f09f33, #de6f3d, #cc3f47, #aa2068, #880088) no-repeat;
	background-size: 300%;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .rebook_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .rebook_button:hover svg {
	fill: #fff;
  }
  
  .rebook_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
  }

