@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

button:focus,
input:focus{
  outline: none;
  box-shadow: none;
}
a,
a:hover{
  text-decoration: none;
}

body{
  font-family: 'Roboto', sans-serif;
}

/* ---------------------- */
.imagePreview{
    width: 90px;
    height: 90px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    cursor: pointer !important;
}
.top_layer_preview{
  z-index: 11;
  cursor: pointer !important;
}
.file-upload{
    display: inline-block;
}

.file-select {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
 
}
.file-select.file-select-box {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 14px;
    cursor: pointer;
}
.file-upload-custom-btn {
    width: 90px;
    height: 90px;
    border: none;
    background-color: #ed192412;
   
    font-size: 30px;
    z-index: 1;
   
   

    
}

.file-upload-custom-btn{
    font-weight: 900;
    position: absolute;
    top: -17px;
    left: -18px;
    font-size: 14px;
    color: rgb(89, 89, 89);
    cursor: pointer !important;
}
.file-select-name{
    margin-left: 15px;
}
.file-select input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.file-select.file-select-box input[type=file]{
    z-index: 2;
}

.file-upload + .file-upload{
    margin-left: 10px;
}
