.hh-grayBox {
	background-color: #F8F8F8;
	margin-bottom: 20px;
	padding: 35px;
  margin-top: 20px;
}
.pt45{padding-top:45px;}
.order-tracking{
	text-align: center;
	width: 33.33%;
	position: relative;
	display: block;
}
.order-tracking .is-complete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	border: 0px solid #AFAFAF;
	background-color: #f7be16;
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.order-tracking .is-complete:after {
	display: block;
	position: absolute;
	content: '';
	height: 14px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 5px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
}
.order-tracking.completed .is-complete{
	border-color: #27aa80;
	border-width: 0px;
	background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
	border-color: #fff;
	border-width: 0px 3px 3px 0;
	width: 7px;
	left: 11px;
	opacity: 1;
}
.order-tracking p {
	color: #A4A4A4;
	font-size: 16px;
	margin-top: 8px;
	margin-bottom: 0;
	line-height: 20px;
}
.order-tracking p span{font-size: 14px;}
.order-tracking.completed p{color: #000;}
.order-tracking::before {
	content: '';
	display: block;
	height: 3px;
	width: calc(100% - 40px);
	background-color: #f7be16;
	top: 13px;
	position: absolute;
	left: calc(-50% + 20px);
	z-index: 0;
}
.order-tracking:first-child:before{display: none;}
.order-tracking.completed:before{background-color: #27aa80;}

.main_container{
    background-image: url(./bg-status.png);
    background-size: cover;
    height: 100vh;
}

#main_container{
	background-image: url('./bg-status.png') !important;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position-y: -56%;
	background-size: cover;
	/* padding-left: 150px; */
	
}

.status_details{
	background: rgba( 255, 255, 255, 0.15 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
height: 450px;
backdrop-filter: blur( 2.5px );
-webkit-backdrop-filter: blur( 2.5px );
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.7);
margin:120px 0;
margin-top: 150px;
width: 500px;
cursor: pointer;

}

.status_details_data{
	font-size: 15px;
	display: flex;
	flex-direction: column;
	height: 400px;
	padding: 15px;
	justify-content: space-around;
	align-content: space-around;

}

.status_container{
	display:flex;
	align-items:center;
	justify-content:space-evenly;
	width: 95%;
	margin: 1px auto;
}
.status_box{
	height: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top:50px;
}

@media only screen and (max-width: 600px) {
	#main_container{
		padding-top: 5px;
		/* background-position-y: -86%; */
		background-size: 100% 100%;
		/* padding-left: 120px; */
	}
	.status_container{
		flex-direction: column;
		margin-top: 100px;
	}
	.status_details{
		width: 100%;
		margin-bottom: 50px;
		margin-top: 50px;
	}
	.status_box{
		display: none;
	}
	
  }

  .status_Details{
	margin-left: "20px" !important;
	font-Size: "18px" !important;
	white-space: "nowrap" !important;
	word-Spacing: "5px" !important;
	letter-Spacing: "3px" !important;
	color: "white";
	height: auto !important;
	width: auto !important;
  }

  /* Rating star */

  .MuiRating-root {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	/* margin-top: 10px; */
	margin-left: 20px;
  }
  /* MuiRating-sizeSmall makeStyles-root-1 css-s80qph-MuiRating-root */
  .center-container {
    display: flex;
    justify-content: center;
}

.rating-container {
    display: flex;
    align-items: center;
}

.glow_border {
    width: fit-content;
    display: flex;
    align-items: center;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    margin-top: 15px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    animation: glowing-border 2s infinite;
}

.glow_border > * {
    margin-right: 10px;
}

@keyframes glowing-border {
    0% {
        box-shadow: 0 0 2px #ffffff; /* Start color */
    }
    50% {
        box-shadow: 0 0 4px #fefdfd; /* Middle color */
    }
    100% {
        box-shadow: 0 0 2px #ffffff; /* End color */
    }
}


  /* Rebook code */
  .rebook_button {
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	/* background: linear-gradient(15deg, #98fc7d, #6395f8, #f2a6aa, #de6f3d, #f89514, #de6f3d, #cc3f47, #aa2068, #ec15ec) no-repeat; */
	background-size: 300%;
	background-color: green;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .rebook_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .rebook_button:hover svg {
	fill: #fff;
  }
  
  .rebook_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
	/* background-color: green; */
  }

  /* Revisit */

  .revisit_button {
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	/* background: linear-gradient(15deg, #98fc7d, #6395f8, #f2a6aa, #de6f3d, #f89514, #de6f3d, #cc3f47, #aa2068, #ec15ec) no-repeat; */
	background-size: 300%;
	background-color: green;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .revisit_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .revisit_button:hover svg {
	fill: #fff;
  }
  
  .revisit_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
	background-color: green;
  }

  .invoice_button {
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	/* background: linear-gradient(15deg, #98fc7d, #6395f8, #f2a6aa, #de6f3d, #f89514, #de6f3d, #cc3f47, #aa2068, #ec15ec) no-repeat; */
	background-size: 300%;
	background-color: blue;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .invoice_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .invoice_button:hover svg {
	fill: #fff;
  }
  
  .invoice_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
	background-color: blue;
  }

