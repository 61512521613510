@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
.fw-700 {
    font-weight: 700;
}

.color-red {
    color: red;
}

.text-align-center {
    text-align: center;
}

.add-service-button {
    display: flex;
    justify-content: end;
    padding: 16px 0px;
}

.aadhar-upload-button {
    display: flex;
    justify-content: space-evenly;
}

.add-button-div {
    display: flex;
    justify-content: end;
    margin-bottom: 11px;
}

.text-transform-none {
    text-transform: none !important;
}

.d-flex {
    display: flex;
}

.css-wjh20t-MuiPagination-ul{
    justify-content:'space-around'
}

/* Custom styles for the filter modal */
.MUIDataTableFilter-root-290 {
    /* background-color: red !important; Replace "your-color" with your desired background color */
background: url(/static/media/filerBg.5419efb5.png);  
left: 500px;
}
.MUIDataTableToolbar-filterPaper-24{
    left: 500px;
    overflow: hidden !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
	display: flex !important;
}

 /* Rebook code */
 .rebook_button {
	
	width: -webkit-fit-content;
	
	width: -moz-fit-content;
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	grid-gap: 0.4rem;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	background: linear-gradient(15deg, #880088, #aa2068, #cc3f47, #de6f3d, #f09f33, #de6f3d, #cc3f47, #aa2068, #880088) no-repeat;
	background-size: 300%;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .rebook_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .rebook_button:hover svg {
	fill: #fff;
  }
  
  .rebook_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
  }


button:focus,
input:focus{
  outline: none;
  box-shadow: none;
}
a,
a:hover{
  text-decoration: none;
}

body{
  font-family: 'Roboto', sans-serif;
}

/* ---------------------- */
.imagePreview{
    width: 90px;
    height: 90px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    cursor: pointer !important;
}
.top_layer_preview{
  z-index: 11;
  cursor: pointer !important;
}
.file-upload{
    display: inline-block;
}

.file-select {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
 
}
.file-select.file-select-box {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 14px;
    cursor: pointer;
}
.file-upload-custom-btn {
    width: 90px;
    height: 90px;
    border: none;
    background-color: #ed192412;
   
    font-size: 30px;
    z-index: 1;
   
   

    
}

.file-upload-custom-btn{
    font-weight: 900;
    position: absolute;
    top: -17px;
    left: -18px;
    font-size: 14px;
    color: rgb(89, 89, 89);
    cursor: pointer !important;
}
.file-select-name{
    margin-left: 15px;
}
.file-select input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.file-select.file-select-box input[type=file]{
    z-index: 2;
}

.file-upload + .file-upload{
    margin-left: 10px;
}

.switch {
    --circle-dim: 1em;
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 2.9em;
    height: 1.5em;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5aeae;
    transition: .4s;
    border-radius: 30px;
  }
  
  .slider-card {
    position: absolute;
    content: "";
    height: var(--circle-dim);
    width: var(--circle-dim);
    border-radius: 20px;
    left: 4px;
    bottom: 4px;
    transition: .4s;
    pointer-events: none;
  }
  
  .slider-card-face {
    position: absolute;
    inset: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    perspective: 1000px;
    border-radius: 50%;
    transition: .4s transform;
  }
  
  .slider-card-front {
    background-color: #DC3535;
  }
  
  .slider-card-back {
    background-color: #379237;
    transform: rotateY(180deg);
  }
  
  input:checked ~ .slider-card .slider-card-back {
    transform: rotateY(0);
  }
  
  input:checked ~ .slider-card .slider-card-front {
    transform: rotateY(-180deg);
  }
  
  input:checked ~ .slider-card {
    transform: translateX(1.5em);
  }
  
  input:checked ~ .slider {
    background-color: #9ed99c;
  }
/* Home.css */
body {
  height:100%;
  background-image: url("https://www.onit.services/static/media/MicrosoftTeams-image.63080be2.png");
  background-size: cover;
  background-position: center;
  font-family: Arial, sans-serif;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}


.home-container {
  height:100vh;
  background-image: url("https://www.onit.services/static/media/MicrosoftTeams-image.63080be2.png");
  background-size: cover;
  background-position: center;
  z-index: 0; 
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  /* justify-content: space-between; */
  position: relative; /* Ensure box container has a relative position */
  z-index: 0; /* Ensure box container is above the background image */
}

.box {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 150px;
  margin-top: 30px;
  position: relative;
  background: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
  z-index: 2; /* Ensure boxes are above the background image */
}

.box:nth-child(even) {
  background-color: transparent;
}

.box:hover {
  transform: translateY(-5px);
}

.box-content {
  padding: 15px;
  flex-grow: 1;
  color: #333;
  text-align: left;
}

.box h2 {
  margin: 0;
  font-size: 1.2rem;

}

.box-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #666;
}

/* Styling for Different Boxes */
.service {
  background-color: #FF5722;
}

.all-tickets {
  /* background-color: #FF5722; */
  color: red;
}

.technicians {
  background-color: #FFC107;
}

/* .add-ticket {
  background-color: #E91E63;
}

.Addtech {
  background-color: #E91E63;
}

.Feedback {
  background-color: #9C27B0;
} */

.client {
  background-color: #FF9800;
}

/* .broadcast {
  background-color: #03A9F4;
}

.role {
  background-color: #795548;
} */

.user {
  background-color: #00BCD4;
}

/* .userdata {
  background-color: #673AB7;
} */

.onboard {
  background-color: #4CAF50;
}

/* .wallet {
  background-color: #FFEB3B;
}

.payment {
  background-color: #2196F3;
} */

.Centre {
  background-color: #8BC34A;
}

.all-centers,
.edit-center,
.add-center {
  width: 100%;
  height: 50px;
  background-color: rgb(109, 167, 167);
  text-align: right;
  border-radius: 5px;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.all-centers:hover,
.edit-center:hover,
.add-center:hover {
  background-color: rgb(89, 145, 145);
}


.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #4245a8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }
  
.accepted {
    font-weight: bold;
    color: green; /* You can adjust the color according to your preference */
  }
.notifications-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .notifications-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .big-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  select.big-input {
    height: auto; /* Adjust select height as needed */
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
  }
  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  color: white;
}

.nav-bar {
  height: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 2rem;
  color: #222;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-logo {
  height: 60px;
}

.nav-bar a {
  text-decoration: none;
  color: darkblue;
}

.nav-bar a:hover {
  color: orange;
}

.nav-bar .button {
  background-color: #33ff36;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: white;
}

.main-cont {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100%;
}

.card {
  position: absolute;

  width: 400px;
  height: 250px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.55) 100%
  );
  border-radius: 1rem;
  border: 2px solid white;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  transition-duration: 1s;
}

.frontcard {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.chip {
  width: 50px;
  height: 40px;
  background-color: gold;

  position: absolute;
  top: 1.5rem;
  left: 1rem;
  border-radius: 5px;
}

.monzo-logo {
  height: 50px;
  position: absolute;
  top: 1.5rem;
  right: 2rem;
}

.card-number {
  position: absolute;
  top: 50%;
  left: 1rem;
  font-size: 1.7rem;
  text-decoration: none;
  color: white;
}
.name {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.logo {
  height: 30px;
  position: absolute;
  bottom: 0.5rem;
  right: 0.8rem;
}
.main_heading {
  font-size: 20px;
  color: #00796a;
  font-weight: 400;
  border: 1px solid rgb(73, 66, 66);
  border-radius: 4px;
  background: lightcyan;
  padding: 7px 10px;
}
@media only screen and (max-width: 600px) {
  .nav-bar {
    flex-direction: column;
    height: 130px;
    justify-content: center;
  }
  .main_heading {
    font-size: 30px;
    padding-top: 15px;
  }
}

.hh-grayBox {
	background-color: #F8F8F8;
	margin-bottom: 20px;
	padding: 35px;
  margin-top: 20px;
}
.pt45{padding-top:45px;}
.order-tracking{
	text-align: center;
	width: 33.33%;
	position: relative;
	display: block;
}
.order-tracking .is-complete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	border: 0px solid #AFAFAF;
	background-color: #f7be16;
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.order-tracking .is-complete:after {
	display: block;
	position: absolute;
	content: '';
	height: 14px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 5px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
}
.order-tracking.completed .is-complete{
	border-color: #27aa80;
	border-width: 0px;
	background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
	border-color: #fff;
	border-width: 0px 3px 3px 0;
	width: 7px;
	left: 11px;
	opacity: 1;
}
.order-tracking p {
	color: #A4A4A4;
	font-size: 16px;
	margin-top: 8px;
	margin-bottom: 0;
	line-height: 20px;
}
.order-tracking p span{font-size: 14px;}
.order-tracking.completed p{color: #000;}
.order-tracking::before {
	content: '';
	display: block;
	height: 3px;
	width: calc(100% - 40px);
	background-color: #f7be16;
	top: 13px;
	position: absolute;
	left: calc(-50% + 20px);
	z-index: 0;
}
.order-tracking:first-child:before{display: none;}
.order-tracking.completed:before{background-color: #27aa80;}

.main_container{
    background-image: url(/static/media/bg-status.4d6019f1.png);
    background-size: cover;
    height: 100vh;
}

#main_container{
	background-image: url(/static/media/bg-status.4d6019f1.png) !important;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position-y: -56%;
	background-size: cover;
	/* padding-left: 150px; */
	
}

.status_details{
	background: rgba( 255, 255, 255, 0.15 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
height: 450px;
backdrop-filter: blur( 2.5px );
-webkit-backdrop-filter: blur( 2.5px );
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.7);
margin:120px 0;
margin-top: 150px;
width: 500px;
cursor: pointer;

}

.status_details_data{
	font-size: 15px;
	display: flex;
	flex-direction: column;
	height: 400px;
	padding: 15px;
	justify-content: space-around;
	align-content: space-around;

}

.status_container{
	display:flex;
	align-items:center;
	justify-content:space-evenly;
	width: 95%;
	margin: 1px auto;
}
.status_box{
	height: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top:50px;
}

@media only screen and (max-width: 600px) {
	#main_container{
		padding-top: 5px;
		/* background-position-y: -86%; */
		background-size: 100% 100%;
		/* padding-left: 120px; */
	}
	.status_container{
		flex-direction: column;
		margin-top: 100px;
	}
	.status_details{
		width: 100%;
		margin-bottom: 50px;
		margin-top: 50px;
	}
	.status_box{
		display: none;
	}
	
  }

  .status_Details{
	margin-left: "20px" !important;
	font-Size: "18px" !important;
	white-space: "nowrap" !important;
	word-Spacing: "5px" !important;
	letter-Spacing: "3px" !important;
	color: "white";
	height: auto !important;
	width: auto !important;
  }

  /* Rating star */

  .MuiRating-root {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	/* margin-top: 10px; */
	margin-left: 20px;
  }
  /* MuiRating-sizeSmall makeStyles-root-1 css-s80qph-MuiRating-root */
  .center-container {
    display: flex;
    justify-content: center;
}

.rating-container {
    display: flex;
    align-items: center;
}

.glow_border {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    margin-top: 15px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    animation: glowing-border 2s infinite;
}

.glow_border > * {
    margin-right: 10px;
}

@keyframes glowing-border {
    0% {
        box-shadow: 0 0 2px #ffffff; /* Start color */
    }
    50% {
        box-shadow: 0 0 4px #fefdfd; /* Middle color */
    }
    100% {
        box-shadow: 0 0 2px #ffffff; /* End color */
    }
}


  /* Rebook code */
  .rebook_button {
	
	width: -webkit-fit-content;
	
	width: -moz-fit-content;
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	grid-gap: 0.4rem;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	/* background: linear-gradient(15deg, #98fc7d, #6395f8, #f2a6aa, #de6f3d, #f89514, #de6f3d, #cc3f47, #aa2068, #ec15ec) no-repeat; */
	background-size: 300%;
	background-color: green;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .rebook_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .rebook_button:hover svg {
	fill: #fff;
  }
  
  .rebook_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
	/* background-color: green; */
  }

  /* Revisit */

  .revisit_button {
	
	width: -webkit-fit-content;
	
	width: -moz-fit-content;
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	grid-gap: 0.4rem;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	/* background: linear-gradient(15deg, #98fc7d, #6395f8, #f2a6aa, #de6f3d, #f89514, #de6f3d, #cc3f47, #aa2068, #ec15ec) no-repeat; */
	background-size: 300%;
	background-color: green;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .revisit_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .revisit_button:hover svg {
	fill: #fff;
  }
  
  .revisit_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
	background-color: green;
  }

  .invoice_button {
	
	width: -webkit-fit-content;
	
	width: -moz-fit-content;
	
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0.3em 1rem;
	cursor: pointer;
	grid-gap: 0.4rem;
	gap: 0.4rem;
	font-weight: bold;
	border-radius: 30px;
	text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
	/* background: linear-gradient(15deg, #98fc7d, #6395f8, #f2a6aa, #de6f3d, #f89514, #de6f3d, #cc3f47, #aa2068, #ec15ec) no-repeat; */
	background-size: 300%;
	background-color: blue;
	color: #fff;
	border: none;
	background-position: left center;
	box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
	transition: background .3s ease;
  }
  
  .invoice_button:hover {
	background-size: 320%;
	background-position: right center;
  }
  
  .invoice_button:hover svg {
	fill: #fff;
  }
  
  .invoice_button svg {
	width: 23px;
	fill: #ebebeb;
	transition: .3s ease;
	background-color: blue;
  }


/* PublicPickDropQr.css */

.mobile-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .mobile-view img {
    width: 150px;
    height: 50px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .mobile-view div {
    color: black;
    font-size: 30px;
    font-weight: bold;
  }
  
  .web-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .web-view img {
    width: 150px;
    height: 60px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .web-view div {
    color: black;
    font-size: 40px;
    font-weight: bolder;
    margin-top: 20px;
  }
  
  /* Media query to switch between mobile and web view */
  @media (min-width: 768px) {
    .mobile-view {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .web-view {
      display: none;
    }
  }
  
body{
    background : rgb(229 228 228)
}

.MuiInputBase-input{
    height : 3.1876em !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

