/* Home.css */
body {
  height:100%;
  background-image: url("https://www.onit.services/static/media/MicrosoftTeams-image.63080be2.png");
  background-size: cover;
  background-position: center;
  font-family: Arial, sans-serif;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}


.home-container {
  height:100vh;
  background-image: url("https://www.onit.services/static/media/MicrosoftTeams-image.63080be2.png");
  background-size: cover;
  background-position: center;
  z-index: 0; 
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content: space-between; */
  position: relative; /* Ensure box container has a relative position */
  z-index: 0; /* Ensure box container is above the background image */
}

.box {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 150px;
  margin-top: 30px;
  position: relative;
  background: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
  z-index: 2; /* Ensure boxes are above the background image */
}

.box:nth-child(even) {
  background-color: transparent;
}

.box:hover {
  transform: translateY(-5px);
}

.box-content {
  padding: 15px;
  flex-grow: 1;
  color: #333;
  text-align: left;
}

.box h2 {
  margin: 0;
  font-size: 1.2rem;

}

.box-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #666;
}

/* Styling for Different Boxes */
.service {
  background-color: #FF5722;
}

.all-tickets {
  /* background-color: #FF5722; */
  color: red;
}

.technicians {
  background-color: #FFC107;
}

/* .add-ticket {
  background-color: #E91E63;
}

.Addtech {
  background-color: #E91E63;
}

.Feedback {
  background-color: #9C27B0;
} */

.client {
  background-color: #FF9800;
}

/* .broadcast {
  background-color: #03A9F4;
}

.role {
  background-color: #795548;
} */

.user {
  background-color: #00BCD4;
}

/* .userdata {
  background-color: #673AB7;
} */

.onboard {
  background-color: #4CAF50;
}

/* .wallet {
  background-color: #FFEB3B;
}

.payment {
  background-color: #2196F3;
} */

.Centre {
  background-color: #8BC34A;
}

.all-centers,
.edit-center,
.add-center {
  width: 100%;
  height: 50px;
  background-color: rgb(109, 167, 167);
  text-align: right;
  border-radius: 5px;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.all-centers:hover,
.edit-center:hover,
.add-center:hover {
  background-color: rgb(89, 145, 145);
}
